import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import "./ContactSection2.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { enqueryContact } from "../action/EnqueryAction";
import { RotatingLines } from "react-loader-spinner";

const ContactSection2 = ({ color, buttoncolor, img }) => {
  const form = useRef();

  console.log(color);
  const {loading}=useSelector((state)=>state.enqueryState)
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("IN");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const enqueryData = {
      name: name,
      email: email,
      phone: phone,
      country: selected,
      message: message,
    };

    try {
      function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      }

      if (!name || !email || !phone || !message) {
        if (!name) {
          setNameError(true);
        }
        if (!email) {
          setEmailError(true);
        } else if (!validateEmail(email)) {
          setEmailValidation(true);
        }
        if (!phone) {
          setPhoneError(true);
        }
        if (!message) {
          setMessageError(true);
        }

        return;
      }
      dispatch(enqueryContact(enqueryData));

        emailjs.sendForm(
          "service_mg9q3wt", //YOUR_SERVICE_ID
          "template_q3081ko", //YOUR_TEMPLATE_ID
          form.current,
          "21lNpD1cwOwuP13Qn" //YOUR_PUBLIC_KEY
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              toast.success("Thank you for reaching out.", {
                position: "bottom-center",
              });
              form.current.reset();
              setName("");
              setEmail("");
              setPhone("");
              setMessage("");
              setEmailError(false)
              setPhoneError(false)
              setMessageError(false)
              setEmailValidation(false)
              setNameError(false)
              setSelected("IN")
              
            }
          },
          (error) => {
            toast.error("Message Not Sent!");
          }
        );
    } catch (error) {
      console.error("Error submitting contact form:", error);
    }
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      {/* Contact Section start */}
      <section className="contact-section" style={{ background: "white" }}>
        <div className="auto-container">
          <div className="row">
            <div className="enqury_main_div">
              <div
                className="right_div aos-init aos-animate"
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <h2 className="right_div_contact_heading">
                  Get a Free{" "}
                  <span style={{ color: `${color}` }}>Quote Today</span>
                </h2>
                <p>
                  Please fill out the quick form and we will be in touch with
                  lightening speed.
                </p>
                <form
                  className="right_div_input_div"
                  ref={form}
                  onSubmit={handleSubmit}
                >
                  <div>
                    <input
                      type="text"
                      placeholder="Name"
                      name="user_name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {nameError ? (
                      <p
                        className="contact_error_message"
                        style={{
                          color: "red",
                          textAlign: "start",
                          margin: "0",
                        }}
                      >
                        Please enter your name
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Email"
                      name="user_email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailValidation ? (
                      <p
                        style={{ color: "red" }}
                        className="contact_error_message"
                      >
                        Please enter valid email
                      </p>
                    ) : emailError ? (
                      <p
                        style={{ color: "red", textAlign: "start" }}
                        className="contact_error_message"
                      >
                        Please enter your email
                      </p>
                    ) : null}
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <input
                      type="tel"
                      placeholder="Phone"
                      className="phone_no_input"
                      name="user_phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {phoneError ? (
                      <p
                        className="contact_error_message"
                        style={{ color: "red", textAlign: "start" }}
                      >
                        Please enter your phone number
                      </p>
                    ) : null}
                  </div>
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={(countryCode) => setSelected(countryCode)}
                    searchPlaceholder="Search Country"
                    searchable
                    name="user_country"
                    defaultCountry="IN"
                  />
                  <div className="text-contat-div">
                    <textarea
                      type="text"
                      name="message"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    {messageError ? (
                      <p
                        className="contact_error_message"
                        style={{ color: "red", textAlign: "start" }}
                      >
                        Please enter your message
                      </p>
                    ) : null}
                  </div>
                  <button
                    style={{ background: `${buttoncolor}` }}
                    className="Contact2_button"
                    type="submit"
                  >
                    {loading ? (
                      <RotatingLines
                        visible={true}
                        height="30"
                        width="30"
                        color="grey"
                        strokeWidth="5"
                        strokeColor="white"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    ) : (
                      <>
                      
                        <span>Contact us</span>
                      </>
                    )}
                  </button>
                </form>
              </div>
              <div
                style={{ backgroundImage: `${img}` }}
                className="left_div aos-init aos-animate"
                data-aos="fade-left"
                data-aos-delay="300"
              ></div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section end */}
    </>
  );
};

export default ContactSection2;
