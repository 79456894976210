import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { enqueryContact } from "../../action/EnqueryAction";
import emailjs from "@emailjs/browser";
import { RotatingLines } from "react-loader-spinner";
import ReactFlagsSelect from "react-flags-select";

const ContactPopUp = ({ setShow }) => {
  const form = useRef();
  const [selected, setSelected] = useState("IN");
  const { loading } = useSelector((state) => state.enqueryState);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const enqueryData = {
      name: name,
      email: email,
      phone: phone,
      country: selected,
      message: message,
    };

    try {
      function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      }

      if (!name || !email || !phone || !message) {
        if (!name) {
          setNameError(true);
        }
        if (!email) {
          setEmailError(true);
        } else if (!validateEmail(email)) {
          setEmailValidation(true);
        }
        if (!phone) {
          setPhoneError(true);
        }
        if (!message) {
          setMessageError(true);
        }

        return;
      }
      dispatch(enqueryContact(enqueryData));
      emailjs
      .sendForm(
        "service_mg9q3wt", // Replace with your service ID
        "template_o26rqua", // Replace with your template ID
        form.current, // Access the actual DOM element
        "21lNpD1cwOwuP13Qn" // Replace with your public key
      )
        .then(
          (result) => {
            if (result.text === "OK") {
              toast.success("Thank you for reaching out.", {
                position: "bottom-center",
              });
              form.current.reset();
              setName("");
              setEmail("");
              setPhone("");
              setMessage("");
              setEmailError(false);
              setPhoneError(false);
              setMessageError(false);
              setEmailValidation(false);
              setNameError(false);
            }
          },
          (error) => {
            toast.error("Message Not Sent!");
          }
        );
    } catch (error) {
      console.error("Error submitting contact form:", error);
    }
  };
  return (
    <div className="contact_popup">
      <div className="contact_popup_container">
        <div className="contact_popup_close">
          <div>
            <h2>Get in touch with us</h2>
            <p>
              Please fill out the quick form and we will be in touch with
              lightening speed.
            </p>
          </div>
          <i className="fas fa-times" onClick={() => setShow(false)}></i>
        </div>
        <form className="contact_inputs" onSubmit={handleSubmit} ref={form}>
          <div className="first_input_container">
            <div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
              {nameError ? <p>please enter your name</p> : ""}
            </div>
            <div>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              {emailValidation ? (
                <p>please enter valid email</p>
              ) : emailError ? (
                <p>please enter your email</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="second_input_container">
            <div>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="phone number"
              />
              {phoneError ? <p>please enter your phone number</p> : ""}
            </div>
            <div style={{ width: "100%" }}>
              <ReactFlagsSelect
                selected={selected}
                onSelect={(countryCode) => setSelected(countryCode)}
                searchPlaceholder="Search Country"
                searchable
                name="user_country"
                defaultCountry="IN"
              />
            </div>
          </div>
          <div className="third_input_container">
            <div>
              <textarea
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="message"
              />
              {messageError ? <p>please enter some text</p> : ""}
            </div>
          </div>
          <button type="submit" className="default-btn">
            {loading ? (
              <RotatingLines
                visible={true}
                height="30"
                width="30"
                color="grey"
                strokeWidth="5"
                strokeColor="white"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              <>
                <span> Submit</span>
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactPopUp;

{
  /* <form className="contact_inputs" ref={form} onSubmit={handleSubmit}>
<div className="input_group">
  <input
    type="text"
    value={name}
    onChange={(e) => setName(e.target.value)}
    placeholder="Name"
  />
  {nameError ? <p>please enter your name</p> : ""}
</div>
<div className="input_group">
  <input
    type="text"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    placeholder="Email"
  />
  {emailValidation ? (
    <p>please enter valid email</p>
  ) : emailError ? (
    <p>please enter your email</p>
  ) : (
    ""
  )}
</div>
<div className="input_group">
  <input
    type="text"
    value={phone}
    onChange={(e) => setPhone(e.target.value)}
    placeholder="Phone Number"
  />
  {phoneError ? <p>please enter your phone number</p> : ""}
</div>
<div className="input_group_text">
  <textarea
    type="text"
    value={message}
    onChange={(e) => setMessage(e.target.value)}
    id=""
    placeholder="Message"
  />
  {messageError ? <p>please enter your message</p> : ""}
</div>
<button type="submit" className="default-btn">
  {loading ? (
    <RotatingLines
      visible={true}
      height="30"
      width="30"
      color="grey"
      strokeWidth="5"
      strokeColor="white"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  ) : (
    <>
      <span> Submit</span>
    </>
  )}
</button>
</form> */
}
