import React from "react";
import { Link } from "react-router-dom";

const CaseStudydetailHero = ({ SinglecaseStudy }) => {

  const link = encodeURI(window.location.href)
  const msg = SinglecaseStudy?.mainheading

  const copyURL = () => {
    // Get the current page URL
    const pageURL = window.location.href;

    // Copy the URL to the clipboard
    navigator.clipboard.writeText(pageURL)
      .then(() => {
        console.log('Page URL copied to clipboard:', pageURL);
        // Show an alert message
        alert('URL copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy page URL to clipboard:', error);
        // Handle the error, maybe show an error message to the user
      });
  };


  return (
    <section className=" casestudy_detail_hero">
      <img src={SinglecaseStudy?.imageurl} alt="" />
      <div className="case_study_black"></div>
      <div className="auto-container casestudy_detail_hero_container">
        <h2>{SinglecaseStudy?.mainheading}</h2>
        <p className="casestudy_type">{SinglecaseStudy?.category}</p>
        <div className="social one" data-aos="fade-up" data-aos-delay="400">
          <p>share</p>
          <Link to={`https://api.whatsapp.com/send?phone=919884036062&text=${msg}%0a${link}`} target="_blank">
            <i class="fa-brands fa-whatsapp"></i>
          </Link>
          <Link to={`https://twitter.com/intent/tweet?url=${link}&text=${msg}`} target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="white"
              width="15"
            >
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
          </Link>
          <Link to={`https://www.linkedin.com/sharing/share-offsite/?mini=true&url=${link}&title=${msg}`} target="_blank">
            <i className="fa-brands fa-linkedin-in" />
          </Link>
          <Link to={`https://telegram.me/share/url?url=${link}&text=${msg}`} target="_blank">
            <i class="fa-brands fa-telegram"></i>
          </Link>
          <Link onClick={copyURL}>
            <i className="fa-regular fa-copy"></i>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CaseStudydetailHero;
