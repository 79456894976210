import React from 'react'

const Loader = () => {
    return (
        <>
            {/* Preloader Start*/}
            <div className="content_preloader">
                <div>
                    <div className="semipolar-spinner">
                        <img src="\assets\img\RAIDEN LOGO.svg" alt="" />
                    </div>
                </div>
            </div>
            {/* preloader end */}
        </>

    )
}

export default Loader